import CompressPdfIcon from 'assets/converterIcons/compress_pdf.svg';
import DeletePagesPdfIcon from 'assets/converterIcons/delete-pages-icon.svg';
import EditPdfIcon from 'assets/converterIcons/edit-pdf-icon.svg';
import Excel2PdfIcon from 'assets/converterIcons/excel_2_pdf.svg';
import {
  default as Jpg2PdfIcon,
  default as Png2PdfIcon,
} from 'assets/converterIcons/jpg_2_pdf.svg';
import MergePdfIcon from 'assets/converterIcons/merge_pdf.svg';
import Pdf2JpgIcon from 'assets/converterIcons/pdf_2_jpg.svg';
import Pdf2PngIcon from 'assets/converterIcons/pdf_2_png.svg';
import Pdf2PptxIcon from 'assets/converterIcons/pdf_2_pptx.svg';
import Pdf2WordIcon from 'assets/converterIcons/pdf_2_word.svg';
import Pdf2XlsIcon from 'assets/converterIcons/pdf_2_xls.svg';
import Pptx2PdfIcon from 'assets/converterIcons/pptx_2_pdf.svg';
import SignPdfIcon from 'assets/converterIcons/sign-pdf-icon.svg';
import SplitPdfIcon from 'assets/converterIcons/split_pdf.svg';
import Word2PdfIcon from 'assets/converterIcons/word_2_pdf.svg';

import { EServiceType, IService } from 'ts/interfaces/services/service';

export const formatsList = {
  '/pdf-to-word': {
    from: 'PDF',
    to: 'DOCX',
  },
  'pdf-to-png': {
    from: 'PDF',
    to: 'PNG',
  },
  '/pdf-to-jpg': {
    from: 'PDF',
    to: 'JPG',
  },
  '/pdf-to-excel': {
    from: 'PDF',
    to: 'XLS',
  },
  '/pdf-to-pptx': {
    from: 'PDF',
    to: 'PPTX',
  },
  '/word-to-pdf': {
    from: 'DOC',
    to: 'PDF',
  },
  '/png-to-pdf': {
    from: 'PNG',
    to: 'PDF',
  },
  '/jpg-to-pdf': {
    from: 'DOC',
    to: 'PDF',
  },
  '/excel-to-pdf': {
    from: 'DOC',
    to: 'PDF',
  },
  '/pptx-to-pdf': {
    from: 'DOC',
    to: 'PDF',
  },
};

export const servicesSectionItems = [
  {
    path: '/edit-pdf',
    name: 'global.edit_pdf',
    icon: EditPdfIcon,
  },
  {
    path: '/pdf-to-word',
    name: 'service_page.pdf_to_word.name',
    icon: Pdf2WordIcon,
  },
  {
    path: '/pdf-to-png',
    name: 'service_page.pdf_to_png.name',
    icon: Pdf2PngIcon,
  },
  {
    path: '/pdf-to-jpg',
    name: 'service_page.pdf_to_jpg.name',
    icon: Pdf2JpgIcon,
  },
  {
    path: '/pdf-to-excel',
    name: 'service_page.pdf_to_excel.name',
    icon: Pdf2XlsIcon,
  },
  {
    path: '/merge-pdf',
    name: 'global.merge_pdf',
    icon: MergePdfIcon,
  },
  {
    path: '/split-pdf',
    name: 'global.split_pdf',
    icon: SplitPdfIcon,
  },
  {
    path: '/compress-pdf',
    name: 'global.compress_pdf',
    icon: CompressPdfIcon,
  },
  {
    path: '/pdf-to-pptx',
    name: 'service_page.pdf_to_pptx.name',
    icon: Pdf2PptxIcon,
  },
  {
    path: '/word-to-pdf',
    name: 'service_page.word_to_pdf.name',
    icon: Word2PdfIcon,
  },
  {
    path: '/png-to-pdf',
    name: 'service_page.png_to_pdf.name',
    icon: Jpg2PdfIcon,
  },
  {
    path: '/jpg-to-pdf',
    name: 'service_page.jpg_to_pdf.name',
    icon: Png2PdfIcon,
  },
  {
    path: '/excel-to-pdf',
    name: 'service_page.excel_to_pdf.name',
    icon: Excel2PdfIcon,
  },
  {
    path: '/pptx-to-pdf',
    name: 'service_page.pptx_to_pdf.name',
    icon: Pptx2PdfIcon,
  },
  {
    path: '/image-to-pdf',
    name: 'service_page.image_to_pdf.name',
    icon: Png2PdfIcon,
  },
  {
    path: '/sign-pdf',
    name: 'global.sign_pdf',
    icon: SignPdfIcon,
  },
  {
    path: '/delete-pages-pdf',
    name: 'global.delete_pages_pdf',
    icon: DeletePagesPdfIcon,
  },
];

export const servicesListWithGroups = {
  convertFromPDF: [
    {
      path: '/pdf-to-word',
      name: 'service_page.pdf_to_word.name',
    },
    {
      path: '/pdf-to-png',
      name: 'service_page.pdf_to_png.name',
    },
    {
      path: '/pdf-to-jpg',
      name: 'service_page.pdf_to_jpg.name',
    },
    {
      path: '/pdf-to-excel',
      name: 'service_page.pdf_to_excel.name',
    },
    {
      path: '/pdf-to-pptx',
      name: 'service_page.pdf_to_pptx.name',
    },
  ],
  convertToPDF: [
    {
      path: '/word-to-pdf',
      name: 'service_page.word_to_pdf.name',
    },
    {
      path: '/png-to-pdf',
      name: 'service_page.png_to_pdf.name',
    },
    {
      path: '/jpg-to-pdf',
      name: 'service_page.jpg_to_pdf.name',
    },
    {
      path: '/excel-to-pdf',
      name: 'service_page.excel_to_pdf.name',
    },
    {
      path: '/pptx-to-pdf',
      name: 'service_page.pptx_to_pdf.name',
    },
  ],
  editPdf: [
    {
      path: '/edit-pdf',
      name: 'global.edit_pdf',
    },
    {
      path: '/sign-pdf',
      name: 'global.sign_pdf',
    },
    {
      path: '/delete-pages-pdf',
      name: 'global.delete_pages_pdf',
    },
    {
      path: '/merge-pdf',
      name: 'global.merge_pdf',
    },
    {
      path: '/split-pdf',
      name: 'global.split_pdf',
    },
    {
      path: '/compress-pdf',
      name: 'global.compress_pdf',
    },
  ],
  pdfActions: [
    {
      path: '/edit-pdf',
      name: 'global.edit_pdf',
    },
    {
      path: '/sign-pdf',
      name: 'global.sign_pdf',
    },
    {
      path: '/delete-pages-pdf',
      name: 'global.delete_pages_pdf',
    },
  ],
};

export const mergePageService = (t: any) => {
  return {
    serviceType: EServiceType.MERGER,
    path: '/merge-pdf',
    name: t('service_page.merge_pdf.name'),
    nameKey: 'service_page.merge_pdf.name',
    title: t('service_page.merge_pdf.title'),
    description: t('service_page.merge_pdf.description'),
    heroFeatures: [
      {
        title: t('service_page.merge_pdf.heroFeatures.0.title'),
        description: t('service_page.merge_pdf.heroFeatures.0.description'),
      },
      {
        title: t('service_page.merge_pdf.heroFeatures.1.title'),
        description: t('service_page.merge_pdf.heroFeatures.1.description'),
      },
      {
        title: t('service_page.merge_pdf.heroFeatures.2.title'),
        description: t('service_page.merge_pdf.heroFeatures.2.description'),
      },
    ],
    icon: Pdf2WordIcon,
    from: 'PDF',
    to: 'DOCX',
    availableFormats: '.pdf',
    aboutSection: {
      title: t('service_page.merge_pdf.aboutSection.title'),
      items: [
        t('service_page.merge_pdf.aboutSection.items.0'),
        t('service_page.merge_pdf.aboutSection.items.1'),
        t('service_page.merge_pdf.aboutSection.items.2'),
      ],
    },
    ctaTitle: t('service_page.merge_pdf.ctaTitle'),
    meta: {
      title: t('service_page.merge_pdf.meta.title'),
      description: t('service_page.merge_pdf.meta.description'),
    },
  };
};

export const editorServicesList = (t: any): IService[] => {
  return [
    {
      serviceType: EServiceType.EDITOR,
      path: '/edit-pdf',
      name: t('service_page.edit_pdf.name'),
      nameKey: 'service_page.edit_pdf.name',
      title: t('service_page.edit_pdf.title'),
      description: t('service_page.edit_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.edit_pdf.heroFeatures.0.title'),
          description: t('service_page.edit_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.edit_pdf.heroFeatures.1.title'),
          description: t('service_page.edit_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.edit_pdf.heroFeatures.2.title'),
          description: t('service_page.edit_pdf.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('service_page.edit_pdf.aboutSection.title'),
        items: [
          t('service_page.edit_pdf.aboutSection.items.0'),
          t('service_page.edit_pdf.aboutSection.items.1'),
          t('service_page.edit_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.edit_pdf.ctaTitle'),
      meta: {
        title: t('service_page.edit_pdf.meta.title'),
        description: t('service_page.edit_pdf.meta.description'),
      },
    },
    {
      serviceType: EServiceType.EDITOR,
      path: '/fill-pdf',
      name: t('service_page.fill_pdf.name'),
      nameKey: 'service_page.fill_pdf.name',
      title: t('service_page.fill_pdf.title'),
      description: t('service_page.fill_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.fill_pdf.heroFeatures.0.title'),
          description: t('service_page.fill_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.fill_pdf.heroFeatures.1.title'),
          description: t('service_page.fill_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.fill_pdf.heroFeatures.2.title'),
          description: t('service_page.fill_pdf.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('service_page.fill_pdf.aboutSection.title'),
        items: [
          t('service_page.fill_pdf.aboutSection.items.0'),
          t('service_page.fill_pdf.aboutSection.items.1'),
          t('service_page.fill_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.fill_pdf.ctaTitle'),
      meta: {
        title: t('service_page.fill_pdf.meta.title'),
        description: t('service_page.fill_pdf.meta.description'),
      },
    },
    {
      serviceType: EServiceType.EDITOR,
      path: '/delete-pages-pdf',
      nameKey: 'service_page.delete_pages_pdf.name',
      name: t('service_page.delete_pages_pdf.name'),
      title: t('service_page.delete_pages_pdf.title'),
      description: t('service_page.delete_pages_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.delete_pages_pdf.heroFeatures.0.title'),
          description: t('service_page.delete_pages_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.delete_pages_pdf.heroFeatures.1.title'),
          description: t('service_page.delete_pages_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.delete_pages_pdf.heroFeatures.2.title'),
          description: t('service_page.delete_pages_pdf.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('service_page.delete_pages_pdf.aboutSection.title'),
        items: [
          t('service_page.delete_pages_pdf.aboutSection.items.0'),
          t('service_page.delete_pages_pdf.aboutSection.items.1'),
          t('service_page.delete_pages_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.delete_pages_pdf.ctaTitle'),
      meta: {
        title: t('service_page.delete_pages_pdf.meta.title'),
        description: t('service_page.delete_pages_pdf.meta.description'),
      },
    },
    {
      serviceType: EServiceType.PDF_CONVERTOR,
      path: '/pdf-converter',
      name: t('service_page.pdf_converter.name'),
      nameKey: 'service_page.pdf_converter.name',
      title: t('service_page.pdf_converter.title'),
      description: t('service_page.pdf_converter.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_converter.heroFeatures.0.title'),
          description: t('service_page.pdf_converter.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_converter.heroFeatures.1.title'),
          description: t('service_page.pdf_converter.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_converter.heroFeatures.2.title'),
          description: t('service_page.pdf_converter.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      // TODO: Removed unsupported images
      // availableFormats:
      //   '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpg, .jpeg, .png, .gif, .svg, .webp, .bmp, .eps',
      availableFormats:
        '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpg, .jpeg, .png, .gif, .webp, .bmp',
      aboutSection: {
        title: t('service_page.pdf_converter.aboutSection.title'),
        items: [
          t('service_page.pdf_converter.aboutSection.items.0'),
          t('service_page.pdf_converter.aboutSection.items.1'),
          t('service_page.pdf_converter.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_converter.ctaTitle'),
      meta: {
        title: t('service_page.pdf_converter.meta.title'),
        description: t('service_page.pdf_converter.meta.description'),
      },
    },
    {
      serviceType: EServiceType.COMPRESSOR,
      path: '/compress-pdf',
      name: t('service_page.compress_pdf.name'),
      nameKey: 'service_page.compress_pdf.name',
      title: t('service_page.compress_pdf.title'),
      description: t('service_page.compress_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.compress_pdf.heroFeatures.0.title'),
          description: t('service_page.compress_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.compress_pdf.heroFeatures.1.title'),
          description: t('service_page.compress_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.compress_pdf.heroFeatures.2.title'),
          description: t('service_page.compress_pdf.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('service_page.compress_pdf.aboutSection.title'),
        items: [
          t('service_page.compress_pdf.aboutSection.items.0'),
          t('service_page.compress_pdf.aboutSection.items.1'),
          t('service_page.compress_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.compress_pdf.ctaTitle'),
      meta: {
        title: t('service_page.compress_pdf.meta.title'),
        description: t('service_page.compress_pdf.meta.description'),
      },
    },
    {
      serviceType: EServiceType.EDITOR,
      path: '/sign-pdf',
      name: t('service_page.sign_pdf.name'),
      nameKey: 'service_page.sign_pdf.name',
      title: t('service_page.sign_pdf.title'),
      description: t('service_page.sign_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.sign_pdf.heroFeatures.0.title'),
          description: t('service_page.sign_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.sign_pdf.heroFeatures.1.title'),
          description: t('service_page.sign_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.sign_pdf.heroFeatures.2.title'),
          description: t('service_page.sign_pdf.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('service_page.sign_pdf.aboutSection.title'),
        items: [
          t('service_page.sign_pdf.aboutSection.items.0'),
          t('service_page.sign_pdf.aboutSection.items.1'),
          t('service_page.sign_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.sign_pdf.ctaTitle'),
      meta: {
        title: t('service_page.sign_pdf.meta.title'),
        description: t('service_page.sign_pdf.meta.description'),
      },
    },
    mergePageService(t),
    {
      serviceType: EServiceType.SPLITTER,
      path: '/split-pdf',
      name: t('service_page.split_pdf.name'),
      nameKey: 'service_page.split_pdf.name',
      title: t('service_page.split_pdf.title'),
      description: t('service_page.split_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.split_pdf.heroFeatures.0.title'),
          description: t('service_page.split_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.split_pdf.heroFeatures.1.title'),
          description: t('service_page.split_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.split_pdf.heroFeatures.2.title'),
          description: t('service_page.split_pdf.heroFeatures.2.description'),
        },
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('service_page.split_pdf.aboutSection.title'),
        items: [
          t('service_page.split_pdf.aboutSection.items.0'),
          t('service_page.split_pdf.aboutSection.items.1'),
          t('service_page.split_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.split_pdf.ctaTitle'),
      meta: {
        title: t('service_page.split_pdf.meta.title'),
        description: t('service_page.split_pdf.meta.description'),
      },
    },
  ];
};

export const mainPageService = (t: any): IService => {
  return {
    serviceType: EServiceType.CONVERTOR,
    path: '/pdf-to-png',
    name: t('main_page.name'),
    nameKey: 'main_page.name',
    title: t('main_page.title'),
    description: t('main_page.description'),
    aboutSection: {
      title: t('main_page.aboutSection.title'),
      items: [
        t('main_page.aboutSection.items.0'),
        t('main_page.aboutSection.items.1'),
        t('main_page.aboutSection.items.2'),
      ],
    },
    ctaTitle: t('main_page.secure_section_title'),
    meta: {
      title: t('main_page.meta.title'),
      description: t('main_page.meta.description'),
    },
    icon: Pdf2PngIcon,
    from: 'PDF',
    to: 'PNG',
    availableFormats: '.pdf',
  };
};

export const servicesList = (t: any): IService[] => {
  return [
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-word',
      name: t('service_page.pdf_to_word.name'),
      nameKey: 'service_page.pdf_to_word.name',
      title: t('service_page.pdf_to_word.title'),
      description: t('service_page.pdf_to_word.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_to_word.heroFeatures.0.title'),
          description: t('service_page.pdf_to_word.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_to_word.heroFeatures.1.title'),
          description: t('service_page.pdf_to_word.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_to_word.heroFeatures.2.title'),
          description: t('service_page.pdf_to_word.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pdf_to_word.aboutSection.title'),
        items: [
          t('service_page.pdf_to_word.aboutSection.items.0'),
          t('service_page.pdf_to_word.aboutSection.items.1'),
          t('service_page.pdf_to_word.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_to_word.ctaTitle'),
      meta: {
        title: t('service_page.pdf_to_word.meta.title'),
        description: t('service_page.pdf_to_word.meta.description'),
      },
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-png',
      name: t('service_page.pdf_to_png.name'),
      nameKey: 'service_page.pdf_to_png.name',
      title: t('service_page.pdf_to_png.title'),
      description: t('service_page.pdf_to_png.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_to_png.heroFeatures.0.title'),
          description: t('service_page.pdf_to_png.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_to_png.heroFeatures.1.title'),
          description: t('service_page.pdf_to_png.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_to_png.heroFeatures.2.title'),
          description: t('service_page.pdf_to_png.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pdf_to_png.aboutSection.title'),
        items: [
          t('service_page.pdf_to_png.aboutSection.items.0'),
          t('service_page.pdf_to_png.aboutSection.items.1'),
          t('service_page.pdf_to_png.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_to_png.ctaTitle'),
      meta: {
        title: t('service_page.pdf_to_png.meta.title'),
        description: t('service_page.pdf_to_png.meta.description'),
      },
      icon: Pdf2PngIcon,
      from: 'PDF',
      to: 'PNG',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-jpg',
      name: t('service_page.pdf_to_jpg.name'),
      nameKey: 'service_page.pdf_to_jpg.name',
      title: t('service_page.pdf_to_jpg.title'),
      description: t('service_page.pdf_to_jpg.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_to_jpg.heroFeatures.0.title'),
          description: t('service_page.pdf_to_jpg.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_to_jpg.heroFeatures.1.title'),
          description: t('service_page.pdf_to_jpg.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_to_jpg.heroFeatures.2.title'),
          description: t('service_page.pdf_to_jpg.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pdf_to_jpg.aboutSection.title'),
        items: [
          t('service_page.pdf_to_jpg.aboutSection.items.0'),
          t('service_page.pdf_to_jpg.aboutSection.items.1'),
          t('service_page.pdf_to_jpg.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_to_jpg.ctaTitle'),
      meta: {
        title: t('service_page.pdf_to_jpg.meta.title'),
        description: t('service_page.pdf_to_jpg.meta.description'),
      },
      icon: Pdf2JpgIcon,
      from: 'PDF',
      to: 'JPG',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-excel',
      name: t('service_page.pdf_to_excel.name'),
      nameKey: 'service_page.pdf_to_excel.name',
      title: t('service_page.pdf_to_excel.title'),
      description: t('service_page.pdf_to_excel.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_to_excel.heroFeatures.0.title'),
          description: t('service_page.pdf_to_excel.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_to_excel.heroFeatures.1.title'),
          description: t('service_page.pdf_to_excel.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_to_excel.heroFeatures.2.title'),
          description: t('service_page.pdf_to_excel.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pdf_to_excel.aboutSection.title'),
        items: [
          t('service_page.pdf_to_excel.aboutSection.items.0'),
          t('service_page.pdf_to_excel.aboutSection.items.1'),
          t('service_page.pdf_to_excel.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_to_excel.ctaTitle'),
      meta: {
        title: t('service_page.pdf_to_excel.meta.title'),
        description: t('service_page.pdf_to_excel.meta.description'),
      },
      icon: Pdf2XlsIcon,
      from: 'PDF',
      to: 'XLS',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pdf-to-pptx',
      name: t('service_page.pdf_to_pptx.name'),
      nameKey: 'service_page.pdf_to_pptx.name',
      title: t('service_page.pdf_to_pptx.title'),
      description: t('service_page.pdf_to_pptx.description'),
      heroFeatures: [
        {
          title: t('service_page.pdf_to_pptx.heroFeatures.0.title'),
          description: t('service_page.pdf_to_pptx.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pdf_to_pptx.heroFeatures.1.title'),
          description: t('service_page.pdf_to_pptx.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pdf_to_pptx.heroFeatures.2.title'),
          description: t('service_page.pdf_to_pptx.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pdf_to_pptx.aboutSection.title'),
        items: [
          t('service_page.pdf_to_pptx.aboutSection.items.0'),
          t('service_page.pdf_to_pptx.aboutSection.items.1'),
          t('service_page.pdf_to_pptx.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pdf_to_pptx.ctaTitle'),
      meta: {
        title: t('service_page.pdf_to_pptx.meta.title'),
        description: t('service_page.pdf_to_pptx.meta.description'),
      },
      icon: Pdf2PptxIcon,
      from: 'PDF',
      to: 'PPTX',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/word-to-pdf',
      name: t('service_page.word_to_pdf.name'),
      nameKey: 'service_page.word_to_pdf.name',
      title: t('service_page.word_to_pdf.title'),
      description: t('service_page.word_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.word_to_pdf.heroFeatures.0.title'),
          description: t('service_page.word_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.word_to_pdf.heroFeatures.1.title'),
          description: t('service_page.word_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.word_to_pdf.heroFeatures.2.title'),
          description: t('service_page.word_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.word_to_pdf.aboutSection.title'),
        items: [
          t('service_page.word_to_pdf.aboutSection.items.0'),
          t('service_page.word_to_pdf.aboutSection.items.1'),
          t('service_page.word_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.word_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.word_to_pdf.meta.title'),
        description: t('service_page.word_to_pdf.meta.description'),
      },
      icon: Word2PdfIcon,
      from: 'DOC',
      to: 'PDF',
      availableFormats: '.doc, .docx',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/png-to-pdf',
      name: t('service_page.png_to_pdf.name'),
      nameKey: 'service_page.png_to_pdf.name',
      title: t('service_page.png_to_pdf.title'),
      description: t('service_page.png_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.png_to_pdf.heroFeatures.0.title'),
          description: t('service_page.png_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.png_to_pdf.heroFeatures.1.title'),
          description: t('service_page.png_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.png_to_pdf.heroFeatures.2.title'),
          description: t('service_page.png_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.png_to_pdf.aboutSection.title'),
        items: [
          t('service_page.png_to_pdf.aboutSection.items.0'),
          t('service_page.png_to_pdf.aboutSection.items.1'),
          t('service_page.png_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.png_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.png_to_pdf.meta.title'),
        description: t('service_page.png_to_pdf.meta.description'),
      },
      icon: Jpg2PdfIcon,
      from: 'PNG',
      to: 'PDF',
      availableFormats: '.png',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/jpg-to-pdf',
      name: t('service_page.jpg_to_pdf.name'),
      nameKey: 'service_page.jpg_to_pdf.name',
      title: t('service_page.jpg_to_pdf.title'),
      description: t('service_page.jpg_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.jpg_to_pdf.heroFeatures.0.title'),
          description: t('service_page.jpg_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.jpg_to_pdf.heroFeatures.1.title'),
          description: t('service_page.jpg_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.jpg_to_pdf.heroFeatures.2.title'),
          description: t('service_page.jpg_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.jpg_to_pdf.aboutSection.title'),
        items: [
          t('service_page.jpg_to_pdf.aboutSection.items.0'),
          t('service_page.jpg_to_pdf.aboutSection.items.1'),
          t('service_page.jpg_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.jpg_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.jpg_to_pdf.meta.title'),
        description: t('service_page.jpg_to_pdf.meta.description'),
      },
      icon: Png2PdfIcon,
      from: 'JPG',
      to: 'PDF',
      availableFormats: '.jpg, .jpeg',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/excel-to-pdf',
      name: t('service_page.excel_to_pdf.name'),
      nameKey: 'service_page.excel_to_pdf.name',
      title: t('service_page.excel_to_pdf.title'),
      description: t('service_page.excel_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.excel_to_pdf.heroFeatures.0.title'),
          description: t('service_page.excel_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.excel_to_pdf.heroFeatures.1.title'),
          description: t('service_page.excel_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.excel_to_pdf.heroFeatures.2.title'),
          description: t('service_page.excel_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.excel_to_pdf.aboutSection.title'),
        items: [
          t('service_page.excel_to_pdf.aboutSection.items.0'),
          t('service_page.excel_to_pdf.aboutSection.items.1'),
          t('service_page.excel_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.excel_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.excel_to_pdf.meta.title'),
        description: t('service_page.excel_to_pdf.meta.description'),
      },
      icon: Excel2PdfIcon,
      from: 'XLS',
      to: 'PDF',
      availableFormats: '.xls, .xlsx',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/pptx-to-pdf',
      name: t('service_page.pptx_to_pdf.name'),
      nameKey: 'service_page.pptx_to_pdf.name',
      title: t('service_page.pptx_to_pdf.title'),
      description: t('service_page.pptx_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.pptx_to_pdf.heroFeatures.0.title'),
          description: t('service_page.pptx_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.pptx_to_pdf.heroFeatures.1.title'),
          description: t('service_page.pptx_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.pptx_to_pdf.heroFeatures.2.title'),
          description: t('service_page.pptx_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.pptx_to_pdf.aboutSection.title'),
        items: [
          t('service_page.pptx_to_pdf.aboutSection.items.0'),
          t('service_page.pptx_to_pdf.aboutSection.items.1'),
          t('service_page.pptx_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.pptx_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.pptx_to_pdf.meta.title'),
        description: t('service_page.pptx_to_pdf.meta.description'),
      },
      icon: Pptx2PdfIcon,
      from: 'PPTX',
      to: 'PDF',
      availableFormats: '.pptx, .ppt',
    },
    {
      serviceType: EServiceType.CONVERTOR,
      path: '/image-to-pdf',
      name: t('service_page.image_to_pdf.name'),
      nameKey: 'service_page.image_to_pdf.name',
      title: t('service_page.image_to_pdf.title'),
      description: t('service_page.image_to_pdf.description'),
      heroFeatures: [
        {
          title: t('service_page.image_to_pdf.heroFeatures.0.title'),
          description: t('service_page.image_to_pdf.heroFeatures.0.description'),
        },
        {
          title: t('service_page.image_to_pdf.heroFeatures.1.title'),
          description: t('service_page.image_to_pdf.heroFeatures.1.description'),
        },
        {
          title: t('service_page.image_to_pdf.heroFeatures.2.title'),
          description: t('service_page.image_to_pdf.heroFeatures.2.description'),
        },
      ],
      aboutSection: {
        title: t('service_page.image_to_pdf.aboutSection.title'),
        items: [
          t('service_page.image_to_pdf.aboutSection.items.0'),
          t('service_page.image_to_pdf.aboutSection.items.1'),
          t('service_page.image_to_pdf.aboutSection.items.2'),
        ],
      },
      ctaTitle: t('service_page.image_to_pdf.ctaTitle'),
      meta: {
        title: t('service_page.image_to_pdf.meta.title'),
        description: t('service_page.image_to_pdf.meta.description'),
      },
      icon: Png2PdfIcon,
      from: 'Image',
      to: 'PDF',
      // TODO: Removed unsupported images
      // availableFormats: '.jpg, .jpeg, .png, .gif, .svg, .webp, .bmp, .eps',
      availableFormats: '.jpg, .jpeg, .png, .gif, .webp, .bmp',
    },
  ];
};
