import { Upload } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';

import openNotification from 'components/commonNotification';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';
import useDisableScroll from 'hooks/useDisableScroll';
import useFileUploadAndConvert from 'hooks/useFileUploadAndConvert';
import { useDispatch, useSelector } from 'react-redux';
import { ENotification } from 'ts/interfaces/common/notification';

import { pushMergeDocument } from 'data/actions/documents';
import { mergeDocumentsListSelector } from 'data/selectors/documents';
import useFileUploadAndCompressNewEditorFlow207 from 'hooks/ab_new_editor_flow_2_0_7/useFileUploadAndCompress';
import useFileUploadAndConverteNewEditorFlow207 from 'hooks/ab_new_editor_flow_2_0_7/useFileUploadAndConvert';
import { useNewEditorFlow } from 'hooks/growthBook/useNewEditorFlow';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import useFileUploadAndCompress from 'hooks/useFileUploadAndCompress';
import useFileUploadAndEdit from 'hooks/useFileUploadAndEdit';
import useFileUploadAndSplit from 'hooks/useFileUploadAndSplit';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Analytics } from 'services/analytics';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import { EModalTypes } from 'ts/enums/modal.types';
import { EServiceType } from 'ts/interfaces/services/service';
import { formatFileSize } from 'utils/formatFileSize';
import { ModalContainer } from '../baseModal/styles';
import { Container, Title } from './styles';
import { validateFile } from 'helpers/validation';

const DragAndDropModal: FC = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const mergeDocumentsList: any = useSelector(mergeDocumentsListSelector());
  const options = useSelector(modalOptionsSelector);

  const service = options?.service;
  const { handleUploadFile } = useFileUploadAndConvert({ service });
  const { handleUploadFile: handleUploadFileNewEditorFlow } =
    useFileUploadAndConverteNewEditorFlow207({ service });
  const { handleUploadFile: handleUploadEditFile } = useFileUploadAndEdit({
    service,
  });
  const { handleUploadFile: handleUploadCompressFile } = useFileUploadAndCompress({ service });
  const { handleUploadFile: handleUploadCompressFileNewEditorFlow } =
    useFileUploadAndCompressNewEditorFlow207({ service });
  const { handleUploadFile: handleUploadSplitFile } = useFileUploadAndSplit({
    service,
  });

  const newEditorFlowAB = useNewEditorFlow();

  const uploadFileCallback = useMemo(
    () => ({
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A]: handleUploadFile,
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_B]: handleUploadFileNewEditorFlow,
    }),
    [handleUploadFile, handleUploadFileNewEditorFlow]
  );
  const uploadFileAndCompressCallback = useMemo(
    () => ({
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A]: handleUploadCompressFile,
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_B]: handleUploadCompressFileNewEditorFlow,
    }),
    [handleUploadCompressFile, handleUploadCompressFileNewEditorFlow]
  );

  const uploadFileConverterCallback = useMemo(
    () => ({
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A]: (file: any) =>
        dispatch(
          toggleModal({
            visible: true,
            type: EModalTypes.CHOOSE_FORMAT_AND_CONVERT,
            options: { file, service },
          })
        ),
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_B]: handleUploadFileNewEditorFlow,
    }),
    [dispatch, handleUploadFileNewEditorFlow, service]
  );

  const [fileData, setFileData] = useState<(File & { uid: string }) | null>(null);
  const [countFiles, setCountFiles] = useState<number>(0);
  let sendNotification = false;

  useDisableScroll();

  const handleBeforeUpload = (
    file: (File & { uid: string }) | null,
    FileList?: File[] & { uid: string }[]
  ) => {
    let isErrorMessage = validateFile(file, t, service?.availableFormats);

    console.log('isErrorMessage--->', isErrorMessage, service?.availableFormats);

    if (mergeDocumentsList?.length + FileList?.length > 15)
      isErrorMessage = t('global.upload_up_to');
    if (FileList?.length && location?.pathname?.includes('/merge-pdf'))
      setCountFiles(FileList?.length);

    if (isErrorMessage || !file) {
      dispatch(toggleModal({ visible: false }));

      if (!sendNotification) {
        sendNotification = true;
        openNotification({
          message: isErrorMessage,
          type: ENotification.ERROR,
        });
        void Analytics.sendEvent({
          event: 'file_upload_status',
          data: {
            status: 'fail',
            place: 'main',
            errorCode: (file?.size || 0) > 100 * 1024 * 1024 ? 'max-size' : 'wrong-format',
            size: formatFileSize(file?.size || 0),
            accurate_size: (file?.size || 0) / 1000000,
            fileCounter: 1,
            file_format: `.${file?.name?.split('.')?.pop()}`,
            is_validation_error: 'true',
          },
        });
        if (service?.serviceType === EServiceType.MERGER) {
          Analytics.sendEvent({ event: 'complete_merge_error_message' });
        }
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    // fix drag html elements on site (png, svg)
    const listener = () => {
      handleBeforeUpload(fileData);
    };

    window.addEventListener('drop', listener);

    return () => {
      window.removeEventListener('drop', listener);
    };
  });

  const handleOnDragLeave = (event: any) => {
    if (!event?.relatedTarget) dispatch(toggleModal({ visible: false }));
  };

  useEffect(() => {
    if (location?.pathname?.includes('/merge-pdf')) {
      if (countFiles && mergeDocumentsList?.length === countFiles) {
        dispatch(toggleModal({ visible: false }));
        navigate('/complete-merge');
      }
    }
  }, [navigate, location, mergeDocumentsList, countFiles, dispatch]);

  return (
    <ModalContainer onDragLeave={(event) => handleOnDragLeave(event)}>
      <Upload
        name="file"
        type="drag"
        showUploadList={false}
        multiple={service?.serviceType === EServiceType.MERGER}
        maxCount={service?.serviceType === EServiceType.MERGER ? 15 : 1}
        customRequest={(file: any) => {
          setFileData(file?.file);
          Analytics.sendEvent({
            event: 'upload_ﬁle_tap',
            data: { method: 'drag_and_drop' },
          });

          if (service?.serviceType === EServiceType.PDF_CONVERTOR) {
            return uploadFileConverterCallback[newEditorFlowAB](file.file);
          } else if (service?.serviceType === EServiceType.COMPRESSOR)
            return uploadFileAndCompressCallback[newEditorFlowAB](file?.file);
          else if (service?.serviceType === EServiceType.MERGER)
            return dispatch(pushMergeDocument({ file: file.file, thumbnail: null }));
          else if (service?.serviceType === EServiceType.EDITOR)
            return handleUploadEditFile(file?.file);
          else if (service?.serviceType === EServiceType.SPLITTER)
            return handleUploadSplitFile(file?.file);
          uploadFileCallback[newEditorFlowAB](file?.file);
        }}
        beforeUpload={(file: File & { uid: string }, FileList: File[] & { uid: string }[]) =>
          handleBeforeUpload(file, FileList)
        }
      >
        <Container>
          <Title>{t('global.drop_your_file_here')}</Title>
        </Container>
      </Upload>
    </ModalContainer>
  );
};

export default DragAndDropModal;
