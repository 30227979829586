import { useFeature } from "@growthbook/growthbook-react";
import { getJsonFomStorage } from "helpers/localStorageHelper";
import {
  GrowthBookTestType,
  LocalStorageProperties,
} from "ts/enums/growthbook";

export const useEmailModalTestValue = (): LocalStorageProperties => {
  const abTests = getJsonFomStorage('abTests')
  const testingEmailModalABC = useFeature(GrowthBookTestType.SEND_FILE_MODAL);

  if (testingEmailModalABC?.off === true) return LocalStorageProperties.SEND_FILE_MODAL_TEST_A

  if (abTests?.includes(LocalStorageProperties.SEND_FILE_MODAL_TEST_B)) return LocalStorageProperties.SEND_FILE_MODAL_TEST_B
  else if (abTests?.includes(LocalStorageProperties.SEND_FILE_MODAL_TEST_C)) return LocalStorageProperties.SEND_FILE_MODAL_TEST_C

  if (testingEmailModalABC?.experiment?.key && testingEmailModalABC?.experimentResult?.value) {
    return `${testingEmailModalABC?.experiment?.key}_${testingEmailModalABC?.experimentResult?.value}` as LocalStorageProperties
  }

  return LocalStorageProperties.NEW_RECURRENT_PRICE_TEST_A
}
