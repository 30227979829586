import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    &:not(.PSPDFKit-Container) {
      /* font-family: 'Montserrat'; */
    }
  }

  html {
    font-size: 100%;
  }

  .ant-popover-content {
    @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

    animation-name: fadeInOpacity;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;
  }

.grecaptcha-badge {
  visibility: hidden;
}


.ant-input-affix-wrapper-focused {
  outline: none !important;
  box-shadow: none !important; 
}

.email-auto-complete-input {
  height: 110px;
  overflow-y: auto !important;
}

.ant-select-item-option-content {
  font-family: Lato !important;
  font-size: 16px;
}

.ant-result {
  padding: 0;
}

.ant-result-icon {
  margin-bottom: 10px;
}

.ant-result-subtitle {
  font-size: 16px;
  color: #40413f;
}

a {
  color: #5766ec;

  &:hover {
    color: #5766ec;
    opacity: 0.8;
  }
}

.ant-modal-content {
  border-radius: 8px !important;
}

.ant-upload {
  padding: 15px 50px;
  will-change: transform;
  transition: transform 200ms ease;

  &:active {
    transform: scale(0.99);
  }

  @media (max-width: 760px) {
    padding: 15px;
  }
}

.ant-popover-inner-content {
  width: 100%;
}

.ant-select-selector {
  border-radius: 8px !important;
}

.ant-tabs-tab {
  padding: 10px 3px;
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 3px solid #5766ec !important;
  z-index: 2;
}

.ant-tooltip-inner {
  text-align: center;
}

.ant-notification-notice-wrapper {
  transform: translate3d(0px, 0px, 0px);
  display: flex;
  width: 441px;
  align-items: center;
  border-radius: 8px !important;
  background: var(--Primary-white, #FFF);
  font-family: Lato !important;

  @media (max-width: 760px) {
    max-width: calc(100vw - 50px)!important;
    width: 100vh !important;
  }

  div, span {
    font-family: Lato !important;
  }
  a, .link {
    font-family: Lato !important;
    text-decoration: underline;
    color: #3758F9;
    cursor: pointer;
  }

  .success {
    border: 2px solid #13C296;
  }

  .error {
    border: 2px solid #F23030;
  }

  .info {
    border: 2px solid #3758F9;
  }

  .thebestpdf-notification {
    padding: 20px !important;
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    max-width: 441px;
    align-items: center;

    @media (max-width: 760px) {
      max-width: calc(100vw - 30px)!important;
      padding: 10px 15px !important;
    }

    .ant-notification-notice-close {
      position: static;
    }

    .ant-notification-notice-with-icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .ant-notification-notice-icon {
        position: static;
      }

      .ant-notification-notice-message {
        max-width: 265px;
        width: 100%;
        color: #212E45;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        margin: 4px 0 4px 20px;
        padding-inline-end: 0;

        @media (max-width: 760px) {
          font-size: 14px;
        }
      }

      .ant-notification-notice-description {
        margin-inline-start: 0;
        font-size: 0;
      }
    }
  }
}

.ant-picker-range {
  height: 38px;
  box-shadow: none;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;

  &:hover:not(.ant-picker-focused){
    border-color: hsl(0, 0%, 70%);
  }

  .ant-picker-active-bar {
    background: #2684ff;
  }
}

.ant-picker-focused {
  box-shadow: 0 0 0 1px #2684ff;
  border-color: #2684ff;
}

.ant-picker-input>input::placeholder {
   color: hsl(0, 0%, 20%);;
}


.ant-popover-inner {
  margin-top: 18px;
  padding: 0 !important;
}

.document-actions-popover {
  .ant-popover-inner {
    padding: 0 !important;
    margin-top: -6px;
    margin-right: 12px;
  }
}

.document-actions-popover-mobile {
  .ant-popover-inner {
    padding: 0 !important;
    margin-top: 0px;
    margin-right: 4px;
  }
}

.react-json-view {
  div, span {
    font-family: monospace !important;
    font-size: 12px !important;
  }

  overflow: scroll;
  width: 100%;
  height: 100%;
  padding: 15px;
}

&::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}

&::-webkit-scrollbar-corner {
  width: 0px;
  height: 0px;
}

&::-webkit-scrollbar-thumb:hover {
  background-color: #76767e;
}

&::-webkit-scrollbar-button {
  display:none;
}

  #solid-payment-form-iframe {
    max-width: 600px;
    width: 100%;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
`;

export const SectionTitle = styled.div`
  color: #40413f;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  width: 100%;
`;

export const FlexColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FlexRowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  height: 88px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  img {
    cursor: pointer;
  }

  @media (max-width: 760px) {
    padding: 15px;
  }
`;

export const ScrollableY = styled.div`
  overflow-y: scroll;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex: 1;
`;

export const Description = styled.div`
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  .ant-upload {
    padding: 0;
    margin-right: 4px;
    display: flex;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const Input = styled.input`
  line-height: 19px;
  width: 100%;
  font-size: 14px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-weight: 500;
  padding: 9px 13px 8px 14px;

  &:focus {
    outline-color: #2684ff;
  }

  &:disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
    color: hsl(0, 0%, 50%);
  }
`;

export const ControlsBarContainer = styled.div`
  background: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

export const TableCell = styled.div`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e6eaee;
  margin: 20px 0;
`;

export const Dot = styled.div<{ error?: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${(props) => (props.error ? 'rgb(228, 77, 95)' : '#52c41a')};
`;

export const SettingPageHeader = styled.span`
  color: #40413f;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  width: 100%;
`;

export const SettingPageDescription = styled.span`
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SectionHeader = styled(SettingPageHeader)`
  font-size: 16px;
  font-weight: 550;
`;

export const SectionDescription = styled(SettingPageDescription)`
  font-size: 13px;
`;

export const SectionItem = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  width: 100%;

  > div {
    width: ${(props) => (props?.fullWidth ? 100 : 50)}%;
    .ant-radio-inner:after {
      background-color: #5766ec;
    }

    .ant-radio-checked:after {
      border: 1px solid #5766ec;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: #5766ec;
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border-color: #5766ec;
      box-shadow: 0 0 0 2px rgb(87 102 236 / 20%);
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: #5766ec;
    }
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 100px;

  @media (min-width: 920px) {
    width: 1360px;
    min-width: 920px;
    max-width: 1360px;
  }

  @media (max-width: 760px) {
    display: flex;
    padding-bottom: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }

  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
  }
`;

export const CommonTextH1 = styled.h1`
  color: var(--Text-color-tittle, #212e45);
  font-family: Montserrat;
  font-size: 2.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 52.8px */

  @media (max-width: 1020px) {
    font-size: 1.75rem;
  }
`;

export const CommonTextH2 = styled.h2`
  color: var(--Text-color-tittle, #212e45);
  font-family: Montserrat;
  font-size: 2.188rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 42px */

  @media (max-width: 1020px) {
    font-size: 1.438rem;
  }
`;

export const CommonTextH3 = styled.h3`
  color: var(--Text-color-tittle, #212e45);
  font-family: Montserrat;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 33.6px */

  @media (max-width: 1020px) {
    font-size: 1.188rem;
  }
`;

export const CommonTextH4 = styled.h4`
  color: var(--Text-color-tittle, #212e45);
  font-family: Montserrat;
  font-size: 1.438rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 29.9px */

  @media (max-width: 1020px) {
    font-size: 1.188rem;
  }
`;

export const CommonTextH5 = styled.h5`
  color: var(--Text-color-tittle, #212e45);
  font-family: Lato;
  font-size: 1.438rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 27.6px */

  @media (max-width: 1020px) {
    font-size: 1.188rem;
  }
`;

export const CommonTextBody = styled.div`
  color: var(--Text-color-tittle, #212e45);
  font-family: Lato;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 800;
  line-height: 130%; /* 23.4px */

  @media (max-width: 1020px) {
    font-size: 1rem;
  }
`;

export const CommonTextBodySmall = styled.div`
  color: var(--Text-color-tittle, #212e45);
  font-family: Lato;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */

  @media (max-width: 1020px) {
    font-size: 0.813rem;
  }
`;

export const CommonTextCaption = styled.div`
  color: var(--Text-color-tittle, #212e45);
  /* Desktop/Caption/Bold */
  font-family: Lato;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 14.4px */

  @media (max-width: 1020px) {
    font-size: 0.688rem;
  }
`;
