import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import detector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import I18NextHttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { ArrayElement } from 'ts/types/utility-types';

export const DEFAULT_LANGUAGE = 'en' as const;

// TODO: Japaneese localization disable
// const additionalLanguages = ['fr', 'de', 'it', 'ja'] as const;
export const additionalLanguages = ['fr', 'de', 'it'] as const;

// TODO: Japaneese localization disable
// export const languages = [DEFAULT_LANGUAGE, 'fr', 'de', 'it', 'ja'] as const;
export const languages = [DEFAULT_LANGUAGE, ...additionalLanguages] as const;

export type SupportedLanguage = ArrayElement<typeof languages>;

export const languageLabels: Record<SupportedLanguage, string> = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  it: 'Italiana',
  // TODO: Japaneese localization disable
  // ja: '日本語',
};

export const localeConfigsDayJs: Record<SupportedLanguage, () => Promise<unknown>> = {
  en: () => import('dayjs/locale/en'),
  fr: () => import('dayjs/locale/fr'),
  de: () => import('dayjs/locale/de'),
  it: () => import('dayjs/locale/it'),
  // TODO: Japaneese localization disable
  // ja: () => import('dayjs/locale/ja'),
};

i18n
  .use(detector)
  .use(ChainedBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    supportedLngs: languages,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: ['path', 'navigator'], // ["path", "navigator", "querystring", 'cookie'],
      lookupFromPathIndex: 0,
      lookupQuerystring: 'lng',
      // caches: ["cookie"],
    },
    backend: {
      backends: [
        I18NextHttpBackend,
        resourcesToBackend((lng: string, ns: string) => import(`./locales/${lng}/${ns}.json`)),
      ],
    },
  });

export const loadLocalesDayJs = () => {
  Promise.all(languages.map((language) => localeConfigsDayJs[language]().then()));
};

export default i18n;
